<template>
  <div class="p-2" id="AdmitDownload">
    <div class="container bg-white pt-3 border border-secondary rounded">
      <div class="row">
        <div class="col-12">
          <table class="paging" width="100%">
            <thead>
            <tr>
              <td>&nbsp;</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td id="main-body">
                <div class="row">
                  <div class="col-12 mb-1 mb-lg-0 text-center" style="position: relative">
                    <img class="logo rounded img-thumbnail" :src="event.logo" style="height: 140px; width: auto;  position: absolute; left: 15px; " alt="" />
                    <div class="title h3 text-dark text-center fw-bold mb-0">13th Bangladesh Physics Olympiad</div>
                    <span class="round h4 text-dark com-name fw-bold">Regional Round <br> ({{ schedule.region.name }} Region)</span><br>
                    <span class="date h5 text-dark fw-bold">Date: {{ format_date(schedule.program_date) }}</span><br>
                    <!--            <span class="text-dark">Mobile</span>-->
                  </div>
                  <div class="col-12 text-center">
                    <span class="card-title h5 text-dark fw-bold d-inline-block border border-black p-1 pe-2 ps-2 rounded mt-2">Admit Card</span>
                  </div>
                </div>
                <table class="table tab-content text-start">
                  <tr class="border-bottom border-top">
                    <th class="text-end pt-1 pb-1" style="width: 130px">Name</th>
                    <th class="text-center pt-1 pb-1" style="width: 50px">:</th>
                    <td class="pt-1 pb-1">{{participantInfo.name}}</td>
                    <td class="pt-1 pb-1" rowspan="5" style="vertical-align: top; min-width: 150px; position: relative">
                      <img :src="avatar" alt="" class="img-thumbnail" style="width: auto; height: 150px; position: absolute; right: 0px; top: 0px;">
                    </td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">Reg. No.</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1">{{participantInfo.reg_no}}</td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">Category</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1">{{participantInfo.categories[0].code}}</td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">Institute</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1">{{participantInfo.school}}</td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">Class</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1">{{participantInfo.class_name.name}}</td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">Region</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1">{{ schedule.region.name }}</td>
                    <td class="pt-1 pb-1"></td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">District</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1">{{participantInfo.district.name}}</td>
                    <td class="pt-1 pb-1"></td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">Address</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1" colspan="2">{{participantInfo.address}}</td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">Program Date</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1" colspan="2">{{ format_date(schedule.program_date) }}</td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">Program Venue</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1" colspan="2">{{ schedule.venue }}</td>
                  </tr>
                  <tr class="border-bottom">
                    <th class="text-end pt-1 pb-1">Reporting Time</th>
                    <th class="text-center pt-1 pb-1">:</th>
                    <td class="pt-1 pb-1" colspan="2">{{ schedule.reporting_time }}</td>
                  </tr>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <footer class="border-top border-black pt-2">
        <p class="text-center">{{ "Powered by: Bits & Bytes" }} | Email: {{ "managerbdpho@gmail.com" }} </p>
      </footer>
      <div id="printBox" class="">
        <button class="btn btn-sm form-control btn-success fw-bold mb-2" onclick="window.print()"><i class="mr-2 fa fa-print"></i> Print</button>
<!--        <br>-->
<!--        <button class="btn btn-sm form-control btn-dark fw-bold" @click="download"><i class="mr-2 fa fa-download"></i> Download</button>-->
<!--        <a target="_blank" :href="downloadLink" class="btn btn-sm form-control btn-dark fw-bold"><i class="mr-2 fa fa-download"></i> Download</a>-->
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: "AdmitDownload",

    data(){
      return {
        participantInfo:null,
        avatar: localStorage.getItem('avatar'),
        event:null,
        round:null,
        schedule:null,
        downloadLink:null
      }
    },
    created() {
      this.participantInfo = JSON.parse(localStorage.getItem('participant'));
      //Redirect to login form
      if (this.participantInfo === null){this.$router.push({name:'Login'});}

      this.$http.get('get-event-code').then(response => {this.event = response.body;});

      this.$http.post('get-round-info', {
        id: JSON.parse(localStorage.getItem('participant')).id,
        reg_no: JSON.parse(localStorage.getItem('participant')).reg_no,
      }).then(response => {
        this.round = response.body.round;
        this.schedule = response.body.schedule;
        console.log(response.body.schedule);
      });

      this.downloadLink = this.$http.options.root+'download-admit/'+this.participantInfo.id+'/'+this.participantInfo.reg_no;

    },
    methods: {
      format_date(value){
        if (value) {
          return moment(String(value)).format('DD/MM/YYYY')
        }
      },
    }
  }
</script>

<style scoped>
  .table tbody tr td, .table tr th, .table tr td{
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    color: #000000 !important;
  }
  .text-dark{
    color: #000000;
  }

  @page {
    size: A4;
    margin: .2in;

  }

  @media only screen {
    table.paging>thead, table.paging>tfoot {
      display: none;
    }
  }

  @media print {
    .title {
      /*margin-top: 15mm;*/
      margin-top: -5mm;
    }

    table.paging thead td {
      height: 0in;
      display: block;
    }
    table.paging tfoot td {
      height: 0.5in;
      display: block;
    }
    #top-menu,#footer,#logo,#partner[data-v-60132a70],#address,#important-link,#quick-link,#visitor-and-facebook{
      display: none !important;
    }
  }

  footer {
    width: 100%;
    height: .5in;
  }

  header {
    width: 100%;
    height: unset;
  }

  @media print {
    header{
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99999;
    }
    footer {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    #main-body{
      width: 100%;
    }

    .logo{
      top: -15px;
      width: 125px !important;
      height: auto !important;
    }
    .title{
      font-size: 20px;
    }
    .date{
      font-size: 18px;
    }
    .round{
      font-size: 18px;
    }
    .card-title{
      font-size: 16px;
    }
  }

  #printBox{
    width: 70px;
    position: fixed;
    right: -150px;
    top: 70px;
    z-index: 9999;
  }

  body:hover #printBox{
    right: 0;
    transition: all 0.2s;
  }
  @media print {
    #printBox{display: none}
  }
</style>
