<template>
  <div class="start_exam">
    <Preloader v-if="!loaded" />
    <div class="container-fluid" v-if="examStatus">
      <div class="row p-2">
        <div class="col-sm-12 col-md-9 m-md-auto bg-white p-2 p-md-3 pt-3 border border-secondary rounded-3">
          <div class="question-header" style="font-family: Rockwell">
            <h3>{{questionPaper.event_title}}</h3>
            <h5>Category-{{participantInfo.categories[0].code}}: {{questionPaper.title}}</h5>
            <div class="row">
              <div class="col-md-4 col-sm-12 text-md-start">Exam Date: {{questionPaper.exam_start_time}}</div>
              <div class="col-md-4 col-sm-12 text-md-center">Duration: {{questionPaper.duration}} Minutes</div>
              <div class="col-md-4 col-sm-12 text-md-end text-sm-start">Mark: {{questionPaper.mark}}</div>
            </div>
            <div class="row fw-bold">
              <div class="col-md-4 col-sm-12 text-md-start">Name: {{participantInfo.name}}</div>
              <div class="col-md-4 col-sm-12 text-md-center">Registration No: {{participantInfo.reg_no}}</div>
              <div class="col-md-4 col-sm-12 text-md-end text-sm-start">Mobile: {{participantInfo.mobile}}</div>
            </div>
            <hr>
            <div class="question-body text-start table-responsive">
              <table class="table table-bordered">
                <tbody>
                <tr class="question-item" v-for="(question,key) in questions" :key="key">
                  <td style="width: 50px">Q.{{key+1}}</td>
                  <td>
                    <div class="question-description" style="text-align: justify" v-html="question.description"></div>
                    <div class="input-group mb-2" v-for="(option,key2) in question.student_options" :key="key2">
                      <div class="input-group-text">
                        <input :checked="question.student_answer_id == option.id" class="form-check-input mt-0" @click="putAnswer(question.id,question.answer_id,option.id,participantInfo.id,question.answer_script_id)" :name="question.id" type="radio" :value="option.id" aria-label="Radio button for following text input">
                      </div>
                      <div class="form-control option" v-html="option.content"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"><button @click="finishExam" class="btn btn-sm btn-success w-100">Finish Exam</button></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="timer" id="timer" v-if="examStatus" v-html="examTimer.showExamMessage"></div>
    </div>
  </div>
</template>

<script>
  import Preloader from "../components/Preloader";
  export default {
    name: "StartExam",
    components:{
      Preloader,
    },
    data(){
      return {
        loaded:false,
        examStatus:false,
        participantInfo:JSON.parse(localStorage.getItem('participant')),
        questionPaper:null,
        questions:[],
        studentAnswers:[],
        answerScript:null,
        timeShow:null,
        examTimer:{
          firstExamStartTime:null, firstExamStartReadableTime:null,
          lastExamStartTime:null, lastExamStartReadableTime:null,
          currentTime:null, currentReadableTime:null,
          timeLeftToStartExam:null, lastTimeToStartExam:null,
          examMinutes:null, examSeconds:null,
          showExamTime:null, showExamMessage:null,
          timerControl:null,
        },
      }
    },
    beforeCreate(){

    },
    created(){
      // console.log(this.questionPaper);
      this.$http.post('start-exam', {
        id: this.participantInfo.id,
        reg_no: this.participantInfo.reg_no,
        mobile: this.participantInfo.mobile,
        category_id: this.participantInfo.categories[0].id,
      }).then(response => {
        this.loaded = true;
        if (response.body.status){
          this.questions = response.body.questions;
          this.answerScript = response.body.answerScript;
          this.answerScriptId = response.body.answerScript.id;
          this.questionPaper = response.body.questionPaper;
          this.examStatus = true;
          this.examTimer.firstExamStartReadableTime = response.body.answerScript.start_time;
          this.examTimer.lastExamStartReadableTime = response.body.answerScript.end_time;
          this.examTimer.timerControl = window.setInterval(this.setTimer,1000);
          // this.examTimer.timerControl = window.setInterval(this.myExamTimer,1000);
        }else {
          this.$router.push({name:'Home'});
        }
      });
    },
    updated(){

    },
    computed:{

    },
    methods:{
      storeStudentAnswers(questions){
        for (let i=0; i<questions.length; i++){
          let q = questions[i];
          this.studentAnswers.push({
            question_id:q.id,
            answer_id:q.answer_id,
            option_id:q.student_answer_id,
            participant_id:this.participantInfo.id,
            answer_script_id:q.answer_script_id
          })
        }
      },

      putAnswer(questionId,answerId,optionId,participantId,answerScriptId){
        if (this.examStatus){
          let index = this.studentAnswers.findIndex(obj => obj.question_id == questionId);
          if (index>-1){
            let obj = {
              question_id:questionId,
              answer_id:answerId,
              option_id:optionId,
              participant_id:participantId,
              answerScript_id:answerScriptId
            };
            this.studentAnswers[index] = obj;
          }else {
            this.studentAnswers.push({
              question_id:questionId,
              answer_id:answerId,
              option_id:optionId,
              participant_id:participantId,
              answer_script_id:answerScriptId
            });
          }

          this.$http.post('put-answer', {
            question_id:questionId,
            answer_id:answerId,
            option_id:optionId,
            participant_id:participantId,
            answer_script_id:answerScriptId
          }).then(response => {
            console.log(response.body);
          });
        }
      },

      finishExam(){
         let msg = 'If you want to finish your exam press OK';
        if (confirm(msg)){
          this.$http.post('finish-exam', {answer_script_id:this.answerScriptId}).then(response => {
            if (response.body.status=='Success'){
              alert('Exam paper was submitted.');
              this.$router.push({name:'Home'});
            }
          });
        }
      },

      myExamTimer(){
        this.examTimer.currentTime = new Date().getTime();
        //Times coming from question paper
        this.examTimer.firstExamStartTime = new Date(this.examTimer.firstExamStartReadableTime).getTime();
        this.examTimer.lastExamStartTime = new Date(this.examTimer.lastExamStartReadableTime).getTime();
        //Times counting upto last time of starting exam
        this.examTimer.timeLeftToStartExam = (this.examTimer.firstExamStartTime - this.examTimer.currentTime);
        this.examTimer.lastTimeToStartExam = (this.examTimer.lastExamStartTime - this.examTimer.currentTime);

        if (this.examTimer.timeLeftToStartExam>0){
          //Counting minutes and seconds to start exam
          this.examTimer.examMinutes = Math.floor((this.examTimer.timeLeftToStartExam % (1000 * 60 * 60)) / (1000 * 60));
          this.examTimer.examSeconds = Math.floor((this.examTimer.timeLeftToStartExam % (1000 * 60)) / 1000);
          this.examTimer.showExamTime = this.examTimer.examMinutes+' : '+this.examTimer.examSeconds;
          this.examTimer.showExamMessage = 'Time Left to Start Exam: '+this.examTimer.showExamTime;
        } else if (this.examTimer.timeLeftToStartExam<0 && this.examTimer.lastTimeToStartExam>0){
          //Counting minutes and seconds to close the exam
          this.examTimer.examMinutes = Math.floor((this.examTimer.lastTimeToStartExam % (1000 * 60 * 60)) / (1000 * 60));
          this.examTimer.examSeconds = Math.floor((this.examTimer.lastTimeToStartExam % (1000 * 60)) / 1000);
          this.examTimer.showExamTime = this.examTimer.examMinutes+' : '+this.examTimer.examSeconds;
          this.examTimer.showExamMessage = 'Time Left: '+this.examTimer.showExamTime;
        }else {
          this.examTimer.showExamMessage = null;
          this.examStatus = false;
        }
      },

      setTimer(){
        let timeObject = null;
        timeObject = this.getCountdownValues(this.examTimer.firstExamStartReadableTime);
        if (timeObject.hours>0 || timeObject.minutes>0 || timeObject.seconds>0){
          this.examTimer.showExamMessage = 'Time Left To Start: '+timeObject.hours+':'+timeObject.minutes+':'+timeObject.seconds;
        }else {
          timeObject = this.getCountdownValues(this.examTimer.lastExamStartReadableTime);
          if (timeObject.hours>0 || timeObject.minutes>0 || timeObject.seconds>0){
            this.examTimer.showExamMessage = '<p>Time Left To <br> Close: '+timeObject.hours+':'+timeObject.minutes+':'+timeObject.seconds+'</p>';
          }else {
            this.examStatus = false;
          }
        }
      },

      getCountdownValues: function( eventDate ) {
        let currentTimeStamp, now, parseDate, timeDifference, convertMilliseconds;
        now = new Date();
        parseDate = function( eDate ) {
          let eventDateParts = eDate.split( /\D/ ),
            dateParts = [], date, i;
          for ( i = 0; i <= eventDateParts.length; i++ ) {
            dateParts[ i ] = parseInt( eventDateParts[ i ], 10 );
          }
          date = new Date( Date.UTC( dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5] ) );
          return date.valueOf();
        };

        currentTimeStamp = ( new Date( Date.UTC ( now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
          now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds() ) ) ).valueOf();
        convertMilliseconds = function( ms ) {
          let d, h, m, s, padNumber;
          s = Math.floor( (ms-21600000) / 1000 );
          m = Math.floor( s / 60 );
          h = Math.floor( m / 60 );
          d = Math.floor( h / 24 );
          padNumber = function( n ) {
            return n < 10 ? '0' + String( n ) : n;
          };
          return {
            total: ms,
            days: d,
            hours: padNumber( h % 24 ),
            minutes: padNumber( m % 60 ),
            seconds: padNumber( s % 60 ),
          };
        };
        timeDifference = parseDate( eventDate ) - currentTimeStamp;
        return convertMilliseconds( timeDifference );
      }
    }
  }
</script>

<style scoped>
  .timer{
    font-family: Rockwell;
    font-size: 20px;
    font-weight: bold;
    position: fixed;
    top: 65px;
    right: 10px;
    background: gray;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
</style>