<template>
  <div id="AdmitDownloadNew">
    <div class="" ref="content" id="admit-card" style="width: 595.28px;color: black;background: white;">
      <h3>PDF for Test</h3>
      <p>Here is some content for testing!!</p>
      <img :src="avatar" alt="">
    </div>
    <button @click="download">Download PDF</button>
  </div>
</template>

<script>
  // import html2canvas from "html2canvas"
  // import jsPDF from "jspdf"
  import html2PDF from 'jspdf-html2canvas';
  export default {
    name: "AdmitDownloadNew",
    data(){
      return {
        avatar: localStorage.getItem('avatar'),
      }
    },
    created(){

    },
    methods:{
      download(){
        // window.html2canvas = html2canvas;
        // const doc = new jsPDF("p","pt","a4");
        // doc.html(document.querySelector("#admit-card"),{
        //   callback: function (pdf) {
        //     pdf.save("mypdf.pdf");
        //   }
        // });
        let page = document.querySelector("#admit-card");
        html2PDF(page, {
          jsPDF: {
            unit: 'px',
            format: 'a4',
          },
          html2canvas: {
            imageTimeout: 15000,
            logging: true,
            useCORS: true,
          },
          imageType: 'image/jpeg, image/png, image/webp',
          imageQuality: 1,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          output: 'jspdf-generate.pdf',
          init: function() {},
          success: function(pdf) {
            pdf.save(this.output);
          }
        })
      }
    }
  }
</script>

<style scoped>
  #admit-card{
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    /*color: red;*/
    margin: 10px auto;
    /*height: 297mm;*/
    width: 8.5in;
    border: 1px solid #444444;
    /*border-radius: 2mm;*/
  }

</style>